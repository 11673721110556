#content-body {
	overflow-x: hidden;
}

.swiper-container {
	overflow: inherit;
	overflow-x: visible;
}

.services-slider .swiper-wrapper .swiper-slide {
	flex-shrink: 1;
}

.top-services-slider .swiper-wrapper .swiper-slide {
	flex-shrink: 1;
}

@media screen and (max-width: 768px) {
	.services-slider.about .swiper-wrapper .swiper-slide {
		flex-shrink: 0;
		width: auto;
	}
	.services-slider .swiper-wrapper .swiper-slide {
		flex-shrink: 0;
	}
}

.products-slider .swiper-wrapper .swiper-slide {
	flex-shrink: 1;
	width: 50%;
}

.building-process-slider .swiper-wrapper .swiper-slide {
	flex-shrink: 1;
	transform: 0;
}

@media screen and (min-width: 768px) and (max-width: 1000px) {
	.products-slider .swiper-wrapper .swiper-slide {
		flex-shrink: 0;
		width: 70%;
	}
}

@media screen and (max-width: 768px) {
	.products-slider .swiper-wrapper .swiper-slide {
		flex-shrink: 0;
		width: 90%;
	}
}

.service-swiper-pagination {
	background: #fff;
	height: 0.75rem;
	width: 3.5rem;
	border-radius: 0.5rem;
	margin: 0 0.5rem;
}

.swiper-pagination-bullet {
	pointer-events: auto;
	cursor: pointer;
}

.swiper-pagination-bullet.swiper-pagination-bullet-active {
	background: #24ce7b;
	pointer-events: auto;
	cursor: pointer;
}

/** CALL TO ACTION BUTTON **/
.tad-button {
	@apply flex justify-center items-center uppercase leading-none font-inter font-semibold text-sm xl:text-base rounded-lg py-4 px-3 sm:py-3 lg:py-4 xl:py-3 lg:px-10;
}

@media screen and (max-width: 320px) {
	.tad-button {
		@apply py-3 px-4 !important;
	}
}

.tad-button.outline {
	@apply transition ease-in-out duration-300;
}

.tad-button .icon {
	@apply my-auto ml-2 w-4 h-4;
}

.tad-button.sidebar-contact {
	@apply py-3 px-7;
}

/** SINGLE PROJECT TAG **/
.tad-button.project-tag {
	@apply ml-0 mr-3 xl:mr-5 px-4 text-white uppercase font-medium rounded-md;

	padding-top: 0.4rem;
	padding-bottom: 0.4rem;
}

/** PARAGRAPH TEXT **/
.paragraph-text {
	@apply font-inter text-base xl:text-lg;
}

/** LOADER **/
.loader, .wait-loader {
	position: fixed;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	background-color: white;
	display: flex;
	justify-content: center;
	align-items: center;
	z-index: 90;
	pointer-events: none;
	opacity: 0;
	transition: all 1s;
}

.wait-loader {
	z-index: 100;
	pointer-events: none;
}

.loader.active {
	opacity: 1;
	transition: none;
}

.wait-loader.active {
	opacity: .5;
	pointer-events: auto;
	cursor: wait;
}

/** REMOVE MARGIN BOTTOM OF LAST PROJECT **
/* .project-section .single-project { */
.project-section .single-project:not(:last-of-type) {
	@apply mb-14 xl:mb-28;
}

/** SECTION HEADING **/
.section-heading-text {
	@apply text-3xl sm:text-4xl text-tad-black font-inter font-semibold capitalize tracking-wide xl:tracking-normal;
}

.section-subheading-text {
	@apply text-base sm:text-lg text-tad-black font-inter font-light mt-1 xl:mt-2 tracking-wide xl:tracking-normal;
}

/** LETS TALK SECTION **/
.lets-talk-section {
	@apply container flex flex-col items-center justify-center py-20 md:py-24 lg:py-32;
}

.lets-talk-section .heading {
	@apply font-inter font-light mb-5 md:mb-10 text-4xl md:text-5xl text-center;
}

.lets-talk-section .subheading {
	@apply font-inter font-bold mb-10 md:mb-20 text-5xl md:text-6xl text-center;
}

/** BORDER **/
.section-border {
	@apply container md:px-20;
}

.section-border hr {
	@apply border-gray-200;
}

/** FOOTER TEXT **/
.footer-heading {
	@apply text-lg font-inter font-medium capitalize mb-2 md:mb-5;
}

.footer-links {
	@apply cursor-pointer hover:underline w-max text-sm font-inter font-light capitalize md:my-2;
}

/** SINGLE PROJECT **/
@media screen and (max-width: 320px) {
	.single-project-image {
		@apply transform scale-120;
	}
}

/** ABOUT PAGE SERVICE COMPONENT **/
.about-service:last-of-type {
	@apply mb-0 md:mb-0;
}

/** SIDEBAR **/
/* .sidebar {
	height: 100%;
}

@media screen and (max-width: 768px) {
	.sidebar {
		height: 7000px !important;
	}
} */

/** OPEN/CLOSE ICON **/
.sidebar-close {
	aspect-ratio: 1/1 !important;
}

.open-menu-icon,
.close-menu-icon {
	width: 1.35rem;
	height: 1.35rem;
	margin: .6rem;
}

@media screen and (max-width: 320px) {
	.open-menu-icon,
	.close-menu-icon {
		width: 1.3rem;
		height: 1.3rem;
		margin: .4rem;
	}
}

@media screen and (min-width: 321px) and (max-width: 400px) {
	.close-menu-icon {
		width: 1.6rem;
		height: 1.6rem;
		margin: .4rem;
	}
}

@media screen and (min-width: 1024px) {
	.open-menu-icon,
	.close-menu-icon {
		width: 1.7rem;
		height: 1.7rem;
		margin: .65rem;
	}
}

/** LABELS TAGS **/
.label-tags {
	@apply py-1 px-4 rounded flex justify-center items-center text-sm text-white font-inter font-semibold uppercase mr-3 mt-3;
}

.label-tags.product {
	@apply py-1 px-4 rounded flex justify-center items-center text-xs sm:text-sm text-white font-inter font-semibold uppercase mr-3 mt-3;
}

/** NAVBAR **/
@media screen and (min-width: 1024px) and (max-width: 1279px) {
	.navbar {
		padding-left: 4.5rem !important;
		padding-right: 4.5rem !important;
	}
}

/** CUSTOM ICON BUTTON **/
.with-icon {
	@apply px-8;
}

.with-icon .text {
	@apply relative w-full flex items-center justify-center -left-4 -right-4;
}

.with-icon .icon {
	@apply absolute w-5 h-5 right-4;
}

@media screen and (max-width: 320px) {
	.with-icon {
		@apply px-9;
	}

	.with-icon .text {
		@apply -left-3 -right-3;
	}

	.with-icon .icon {
		@apply right-2;
	}
}

/** STOP SCROLLING **/
.stop-scrolling {
	height: 100%;
	overflow-y: hidden;
}